<div>
	<div class="d-flex justify-content-between">
		<h4 mat-dialog-title>{{ name }}</h4>

		<div style="display: flex;">
			<button mat-icon-button (click)="zoomIn()" matTooltip="Zoom +">
				<mat-icon>zoom_in</mat-icon>
			</button>
			<button mat-icon-button (click)="zoomOut()" matTooltip="Zoom -">
				<mat-icon>zoom_out</mat-icon>
			</button>
			<button mat-icon-button (click)="restore()" matTooltip="Restaurar">
				<mat-icon>restore</mat-icon>
			</button>
		</div>
	</div>

	<div>
		<div class="modal-form-body">
			<br>
			<drag-scroll class="align-self-center text-center">
				<img drag-scroll-item id="img-zoom-ngx" [src]="image">
			</drag-scroll>
		</div>
		<div class="btn-sec d-flex justify-content-between mt-3">
			<span></span>

			<div class="button-container">
				<button class="msr-3" mat-raised-button color="primary" (click)="onClose()">{{ 'TRANSLATE.OK' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>