<div class="container">
    <h3 mat-dialog-title>{{data.title}}</h3>
    <div mat-dialog-content>
        <div *ngIf="loadView" class="spinner-progress">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <div *ngIf="!loadView" class="clearfix row">
            <div class="col-lg-12 change_shape">
                <p [innerHTML]="loadView ? data.wait : data.body"></p>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-between mt-1">
        <span *ngIf="data.cancel == null"></span>
        <button *ngIf="data.cancel != null" mat-raised-button (click)="onNoClick()">
            {{data.cancel}}
        </button>
        <button mat-raised-button [disabled]="loadView" mat-button (click)="onYesClick()" color="primary">
            <span style="color: white;">{{data.confirm}}</span>
        </button>
    </div>
</div>
  