import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {

  AdvancedLayout,
  ButtonsConfig,
  ButtonsStrategy,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,

} from '@ks89/angular-modal-gallery';

@Component({
    selector: 'app-gallery-image',
    templateUrl: './gallery-image.component.html',
    styleUrls: ['./gallery-image.component.scss']
})
export class GalleryImageComponent implements OnInit {

  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  buttonsConfigSimple: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.SIMPLE
  };
  
  imagesGallery:  Image[] = [];

  constructor(
    public dialogRef: MatDialogRef<GalleryImageComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.onsetGalleryImages(this.data)
  }

  onsetGalleryImages(element){
    this.imagesGallery = []

    if(element.galleryPersonalized){
      for (var i = 0; i < element.galleryPersonalized.length; i++) {
        if(element.galleryPersonalized[i] != null){
          this.imagesGallery.push(
            new Image(
                Number(i + 1),
                { // modal
                  img: element.galleryPersonalized[i].split("base64,")[1] ? element.galleryPersonalized[i] : 'data:image/png;base64,'+ element.galleryPersonalized[i],
                  description: null, //element.materialImages[i].displayName,
                  extUrl: 'https://www.google.com'
                }
            ),
          )
        }
      }
    }
  }

  openImageModalRowDescription(image: Image){
    const index: number = this.getCurrentIndexCustomLayout(image, this.imagesGallery);
    this.customPlainGalleryRowDescConfig = Object.assign({}, this.customPlainGalleryRowDescConfig, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  getName(text){
    return (text == 'undefined' || text == null ) ? 'Sin descripción' :  text 
  }

  yes() {
    this.dialogRef.close("yes");
  }
}
