<div class="my-dialog">
    <div>
        <h2 mat-dialog-title>{{data.name}}</h2>

        <div class="my-app-custom-plain-container-with-desc" style="justify-content: center;">
            <ng-container *ngFor="let img of imagesGallery; let i = index">
                <figure *ngIf="i <= 2" class="my-app-custom-image-with-desc">
                    <div *ngIf="imagesGallery.length > 3 && i == 2" class="more">
                        <a class="text-img" (click)="openImageModalRowDescription(img)"> +{{imagesGallery.length - 2 - 1}} más </a>
                    </div>
                    <img [src]="img.modal.img"
                        (click)="openImageModalRowDescription(img)"/>
                    <!--<figcaption class="description">{{getName(img.modal.description)}}
                    </figcaption>-->
                </figure>
            </ng-container>
        </div>
        <ks-modal-gallery [id]="1" [modalImages]="imagesGallery" [buttonsConfig]="buttonsConfigSimple" [plainGalleryConfig]="customPlainGalleryRowDescConfig"></ks-modal-gallery>
    </div>

    <mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="end center">
        <button mat-raised-button (click)="yes()" color="primary">Cerrar</button>
    </mat-dialog-actions>
</div>