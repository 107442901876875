import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountInfoBank } from 'src/app/models/superadmin/customers/user.model';
import { BankTlanService } from 'src/app/services/superadmin/customers/bank-bancatlan.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';
import { SnackBarService } from 'src/app/services/system/snackbar.service';

@Component({
  selector: 'app-modal-pin',
  templateUrl: './modal-pin.component.html',
  styleUrls: ['./modal-pin.component.scss']
})
export class ModalPinComponent implements OnInit {

  inputNumber: FormGroup;
  loadValidateBank: boolean = false
  token = null
  
  constructor(
    private bankBancatlanService : BankTlanService,
    private snackBarService: SnackBarService,
    private errorService: ErrorManagementService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalPinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.inputNumber = this.fb.group({
      first: ['', Validators.compose([Validators.required])],
      second: ['', Validators.compose([Validators.required])],
      third: ['', Validators.compose([Validators.required])],
      fourth: ['', Validators.compose([Validators.required])],
      fifth: ['', Validators.compose([Validators.required])],
      sixth: ['', Validators.compose([Validators.required])]
    });
   }

  ngOnInit(): void {
    this.ongeneratePIN()
  }

  ongeneratePIN(){
    let send: AccountInfoBank = {
      document_type: this.data.documentTypeId.code,
      dni: this.data.titularIdentificationNumber,
      account_number: this.data.accountNumber,
      first_name: this.data.titularFirstName1,
      last_name:this.data.titularLastName1
    }

    this.bankBancatlanService.generatePinBank(send).subscribe(res => {
      if(res.response == false){
        this.snackBarService.error({ message: res.data})
      }else{
        if(res.respuesta.estado.codigo == '0000'){
          this.token = res.respuesta.estado.token
          this.snackBarService.success({ message:"Pin ha sido generado satisfactoriamente."})
        }else{
          this.snackBarService.error({ message: res.respuesta.estado.descripcion == '' ? res.respuesta.estado.detalleTecnico : res.respuesta.estado.descripcion})
        }
      }
    }, error => {
      this.errorService.getErrorMessage(error)
    })
  }

  onvalidatePIN(){
    if(!this.inputNumber.valid){
      this.snackBarService.error({ message: "Debe ingresar los 6 dígitos." })
      return
    }

    let send: AccountInfoBank = {
      document_type: this.data.documentTypeId.code,
      dni: this.data.titularIdentificationNumber,
      account_number: this.data.accountNumber,
      first_name: this.data.titularFirstName1,
      last_name:this.data.titularLastName1,
      pin: this.inputNumber.value.first + this.inputNumber.value.second + this.inputNumber.value.third + 
      this.inputNumber.value.fourth + this.inputNumber.value.fifth + this.inputNumber.value.sixth,
      token: this.token
    }

    this.loadValidateBank = true
    this.bankBancatlanService.verificarPinBank(send).subscribe(res => {
      this.loadValidateBank = false
      if(res.response == false){
        this.snackBarService.error({ message: res.data})
      }else{
        if(res.Respuesta.estado.codigo == '0000'){
          this.snackBarService.success({ message:"Verificación de pin exitosa."})
          this.dialogRef.close('success')
        }else{
          this.snackBarService.error({ message: res.Respuesta.estado.descripcion == '' ? res.Respuesta.estado.detalleTecnico : res.Respuesta.estado.descripcion})
        }
      }
    }, error => {
      this.loadValidateBank = false
      this.errorService.getErrorMessage(error)
    })
  }
}
