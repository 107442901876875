import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';

declare var google: any;

@Injectable({ providedIn: 'root' })
export class GeocodeService {
    private geocoder: any;

    constructor(private mapLoader: MapsAPILoader) {

    }

    private initGeocoder() {
        console.log('Init geocoder!');
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
        return from(this.mapLoader.load())
            .pipe(
                tap(() => this.initGeocoder()),
                map(() => true)
            );
        }
        return of(true);
    }

    geocodeAddress(location: string): Observable<any> {
        console.log('Start geocoding!');
        return this.waitForMapsToLoad().pipe(
        // filter(loaded => loaded),
        switchMap(() => {
            return new Observable(observer => {
            this.geocoder.geocode({ 'address': location }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                console.log('Geocoding complete!');
                console.log(results)
                observer.next({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
                } else {
                console.log('Error - ', results, ' & Status - ', status);
                observer.next({ lat: 0, lng: 0 });
                }
                observer.complete();
            });
            })
        })
        )
    }

    getAddress(latitude, longitude): Observable<any> {
        console.log('Start geocoding!');
        return this.waitForMapsToLoad().pipe(
        // filter(loaded => loaded),
        switchMap(() => {
            return new Observable(observer => {
            this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {

                observer.next({
                    results
                });

                } else {
                console.log('Error - ', results, ' & Status - ', status);
                observer.next({ lat: 0, lng: 0 });
                }
                observer.complete();
            });
            })
        })
        )
    }
}