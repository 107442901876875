<div class="close-btn-outer">
    <button mat-mini-fab color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="row">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
            <agm-map [latitude]="location?.lat" [longitude]="location?.lng" [zoom]="18" (mapReady)="mapReady($event)"
                (centerChange)="centerChange($event)" [gestureHandling]="'greedy'">
                <button id="CurrentPos" style="background: white; margin-top: 9px;margin-left: 5px; margin-right: 5px;" mat-icon-button
                (click)="setCurrentLocation()">
                    <mat-icon aria-label="label">gps_fixed</mat-icon>
                </button>

                <mat-form-field id="SearchCustom" class="width-form" appearance="outline">

                    <input matInput type="text" [(ngModel)]="address" required [ngModelOptions]="{standalone: true}" type="text"
                        (keydown.enter)="$event.preventDefault()" placeholder="Busca una dirección" autocorrect="off"
                        autocapitalize="off" spellcheck="off" type="text" #search>
                    <button color="primary" *ngIf="!loading" mat-icon-button matSuffix [attr.aria-pressed]="true"
                        (click)="showLocation()">
        
                        <mat-icon aria-label="" style="margin-top: -6px;">search</mat-icon>
                    </button>
                    <mat-spinner *ngIf="loading" matSuffix [diameter]="20"></mat-spinner>
        
                </mat-form-field>

                <agm-marker [latitude]="centerLocation?.lat" [longitude]="centerLocation?.lng" [markerDraggable]="false">
                </agm-marker>
            </agm-map>
        </div>
    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end mt-2">  
    <div class="text-muted">
        <!--<div>
            <mat-checkbox [checked]="this.activeReplacemat-dialog-actions" (change)="changeCheck($event)">
            </mat-checkbox> <span style="margin-left: 5px;"> Reemplazar con la dirección del mapa</span>
        </div>-->

        <div>
            <div class="button-container">
                <div class="spinner-container" *ngIf="loadSave">
                    <mat-spinner diameter="24"></mat-spinner>
                </div>
                <button (click)="onSave()" type="submit" mat-raised-button color="primary">Guardar</button>
            </div>
        </div>
    </div> 
</div>