<div>
  <div class="d-flex justify-content-between">
      <h4 mat-dialog-title>{{ 'TRANSLATE.SORT_MANUALLY' | translate }}</h4>
  </div>
  
  <div>
    <div class="modal-form-body">
      <div class="row w-100 mt-3">
        <div class="col-md-12 mb-2">
          <mat-form-field class="example-full-width">
            <mat-label>{{'TRANSLATE.POSITION' | translate}}</mat-label>
            <input type="number" [(ngModel)]="position" matInput autofocus min="1" step="1" [min]="1" [max]="leng">
            <mat-hint align="start"><strong>Min: </strong>{{'1'}} </mat-hint>
            <mat-hint align="end"><strong>Max: </strong>{{leng}}</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-12 mb-2">
          <mat-slider color="primary" style="width: 100%;" [max]="leng" [min]="1" [step]="1" [thumbLabel]="true"
            [(ngModel)]="position" [ngModelOptions]="{standalone: true}">
          </mat-slider>
        </div>
      </div>
    </div>
    
    <div class="btn-sec d-flex justify-content-between mt-3">
      <button type="button" mat-button (click)="onCancel()">{{ 'TRANSLATE.CANCEL' | translate}}</button>

      <div class="button-container">
        <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()">{{ 'TRANSLATE.SAVE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
  