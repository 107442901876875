import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragScrollModule } from 'ngx-drag-scroll';

import { MaterialModule } from './material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { TranslateModule } from '@ngx-translate/core';
import { SortManuallyComponent } from './sort-manually/sort-manually.component';
import { ModalPinComponent } from './modal-pin/modal-pin.component';
import { FlexLayoutModule } from '@angular/flex-layout';

// ********************** angular-modal-gallery *****************************
import 'hammerjs'; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save hammerjs`)
import 'mousetrap'; // Mandatory for angular-modal-gallery 3.x.x or greater (`npm i --save mousetrap`)
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { GalleryImageComponent } from './gallery-image/gallery-image.component';
import { LocationMapComponent } from './location-map/location-map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    CustomDialogComponent,
    ViewImageComponent,
    SortManuallyComponent,
    ModalPinComponent,
    LocationMapComponent,
    GalleryImageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DragScrollModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    GalleryModule.forRoot(),
    AgmCoreModule.forRoot({
			apiKey: environment.googleApiKey,
			libraries: ['places']
		}),
  ],
  entryComponents: [
    CustomDialogComponent,
    SortManuallyComponent,
    ModalPinComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    CustomDialogComponent,
    SortManuallyComponent,
    GalleryImageComponent
  ]
})
export class SharedModule {}
