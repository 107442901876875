<div>
    <div>
        <div class="div-content">
            Ingresa el pin dinámico, enviada al teléfono proporcionado.
        </div>
        <div class="clearfix row" style="place-content: center;">
            <form [formGroup]="inputNumber">
                <div fxLayout="row" fxLayoutAlign="center center" style="text-align: center;">
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="first" style="font-size: 16px;" maxlength="1"
                                required onkeyup="if (this.value.length == 1) input2.focus()" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="second" style="font-size: 16px;" maxlength="1"
                                required id="input2" onkeyup="if (this.value.length == 1) input3.focus()" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="third" style="font-size: 16px;" maxlength="1"
                                required id="input3" onkeyup="if (this.value.length == 1) input4.focus()" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="fourth" style="font-size: 16px;" maxlength="1"
                                required id="input4" onkeyup="if (this.value.length == 1) input5.focus()" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="fifth" style="font-size: 16px;" maxlength="1"
                                required id="input5" onkeyup="if (this.value.length == 1) input6.focus()" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                    <div fxFlex="16" fxFlex.gt-md="16" class="box-inpt px-1">
                        <mat-form-field appearance="outline" class="text-center w-100">
                            <input matInput placeholder="" formControlName="sixth" style="font-size: 16px;" maxlength="1"
                                required id="input6" onkeydown="return /[0-9]/i.test(event.key)">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="div-content">
            ¿No recibiste el pin? <span class="link" (click)="ongeneratePIN()">Volver a enviar pin</span>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="mt-1">
        <button mat-raised-button color="primary" (click)="onvalidatePIN()">
            <div style="white-space: nowrap;display: flex; justify-content:center; align-items: center;">
                <div *ngIf="loadValidateBank" class="spinner-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
                Validar pin
            </div>
        </button>
    </div>
</div>