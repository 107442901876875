import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss']
})
export class ViewImageComponent implements OnInit {

  image: any = null
  name: any = ''
  width = 100
  height = 100

  constructor(
    public dialogRefComponent: MatDialogRef<ViewImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if(this.data != null) {
      this.image = this.data.image
      this.name = this.data.name
    }
  }

  ngAfterViewInit() {
    let myImg = document.getElementById("img-zoom-ngx");
    this.width = myImg.clientWidth;
    this.height = myImg.clientHeight;
  }

  zoomIn(){
    let myImg = document.getElementById("img-zoom-ngx");
    let currWidth = myImg.clientWidth;
    let currHeight = myImg.clientHeight;
    if(currWidth == 2500) return false;
    else{
      let dis = currHeight * 100 / currWidth
      myImg.style.width = (currWidth + 100) + "px";
      myImg.style.height = (currHeight + dis) + "px";
    }
  } 

  zoomOut(){
    let myImg = document.getElementById("img-zoom-ngx");
    let currWidth = myImg.clientWidth;
    let currHeight = myImg.clientHeight;

    let dis = currHeight * 100 / currWidth
    if((currHeight - dis) <= 450) {
      myImg.style.width = this.width + "px";
      myImg.style.height = this.height + "px";
    }
    else{
      myImg.style.width = (currWidth - 100) + "px";
      myImg.style.height = (currHeight - dis) + "px";
    }
  }

  restore(){
    let myImg = document.getElementById("img-zoom-ngx");
    
    myImg.style.width = this.width + "px";
    myImg.style.height = this.height + "px";
  }

  onClose(){
    this.dialogRefComponent.close('Close')
  }
}
